import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Component, Inject} from '@angular/core';
import {SharedVariablesService} from '../services/sharedVariables.service';
import {BasicSurveyMonkeyFeedbackData, SurveyMonkeyFeedbackService} from '../services/survey-monkey-feedback.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-user-feedback-dialog',
  templateUrl: 'user-feedback-dialog.component.html',
  styleUrls: ['./user-feedback-dialog.component.scss']
})
export class UserFeedbackDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<UserFeedbackDialogComponent>,
    private toastr: ToastrService,
    private sendFeedbackService: SurveyMonkeyFeedbackService,
    @Inject(MAT_DIALOG_DATA) public model: BasicSurveyMonkeyFeedbackData) {}

  cancel(): void {
    this.dialogRef.close();
  }

  submit(): void {
    if(this.model.score === null || this.model.score === undefined) {
      this.toastr.error("Kies één van de smileys.");
    }
    else {
      this.sendFeedbackService.sendFeedback.emit(this.model);
      this.dialogRef.close();
    }
  }
}
