import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {
  GraphQlSurveyMonkeyFeedbackSubmissionInput,
  GraphQlSurveyMonkeyFeedbackSubmissionResponse,
  SubmitSurveyMonkeyFeedbackGQL
} from '../../generated/graphql';
import {environment} from '../../environments/environment';
import {RMedewerker} from './somtoday.service';
import {ToastrService} from 'ngx-toastr';
import {Store} from '@ngrx/store';
import {AppState, selectMe} from '../state/app.state';

@Injectable({
  providedIn: 'root'
})
export class SurveyMonkeyFeedbackService implements OnDestroy {

    public sendFeedback: EventEmitter<BasicSurveyMonkeyFeedbackData> = new EventEmitter<BasicSurveyMonkeyFeedbackData>();

    public onFeedbackSent: EventEmitter<GraphQlSurveyMonkeyFeedbackSubmissionResponse> =
      new EventEmitter<GraphQlSurveyMonkeyFeedbackSubmissionResponse>();

    private me: RMedewerker;

    private subscriptions = [];

    constructor(
        private surveyMonkeyFeedbackMutation: SubmitSurveyMonkeyFeedbackGQL,
        private toastr: ToastrService,
        private store: Store<AppState>
    ) {
        this.subscriptions.push(this.sendFeedback.subscribe(data => this.doSendFeedback(data)));
        this.subscriptions.push(this.onFeedbackSent.subscribe(response => this.handleFeedbackSent(response)));
        this.subscriptions.push(this.store.select(selectMe).subscribe(value => this.me = value));
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    private doSendFeedback(data: BasicSurveyMonkeyFeedbackData) {
        const input: GraphQlSurveyMonkeyFeedbackSubmissionInput = {
            bericht: data.message,
            score: data.score,
            inleverDatumTijd: new Date(Date.now()).toISOString(),
            naam: this.me.roepnaam + ' ' + this.me.achternaam,
            email: this.me.additionalObjects.email,
            pagina: data.route,
            userAgent: window.navigator.userAgent,
            schermresolutie: 'Breedte: ' + window.screen.width + ', Hoogte: ' + window.screen.height,
            versie: environment.configurationName + ':' + environment.buildMoment
        };

        const sub = this.surveyMonkeyFeedbackMutation.mutate({arg1: input}).subscribe(response => {
            sub.unsubscribe();
            this.onFeedbackSent.emit(response.data.sendFeedback);
        });
    }

    private handleFeedbackSent(response: GraphQlSurveyMonkeyFeedbackSubmissionResponse) {
        if (response.success.valueOf() === true) {
            this.toastr.info('Bedankt voor je feedback!');
        }
        else {
            this.toastr.error('Er is iets fout gegaan bij het verwerken van je feedback. Zou je het later nog een keer willen proberen?');
        }
    }
}

export interface BasicSurveyMonkeyFeedbackData {
    route: string;
    score: number;
    message: string;
}
