<div class="title">Wat is je ervaring met Somtoday Connect?</div>
<span class="close" (click)="cancel()"><img src="assets/img/argent-a-saltire-azure.png" alt="sluiten"></span>
<div mat-dialog-content class="dialog">
    <div class="radioContainer">
      <img src="assets/img/feedback/mad.png"> <input type="radio" id="1" value="1" name="score" [(ngModel)]="model.score">
    </div>
    <div class="radioContainer">
      <img src="assets/img/feedback/not_amused.png"><input type="radio" id="2" value="2" name="score" [(ngModel)]="model.score">
    </div>
    <div class="radioContainer">
      <img src="assets/img/feedback/neutral.png"><input type="radio" id="3" value="3" name="score" [(ngModel)]="model.score">
    </div>
    <div class="radioContainer">
      <img src="assets/img/feedback/amused.png"> <input type="radio" id="4" value="4" name="score" [(ngModel)]="model.score">
    </div>
    <div class="radioContainer">
      <img src="assets/img/feedback/happy.png"> <input type="radio" id="5" value="5" name="score" [(ngModel)]="model.score">
    </div>

    <textarea matInput class="text" [(ngModel)]="model.message" placeholder="Vertel hier waarom..."></textarea>
</div>
<div class="buttons" mat-dialog-actions>
    <button mat-button class="button" (click)="cancel()">Annuleren</button>
    <button mat-button class="button" (click)="submit()" cdkFocusInitial>Versturen</button>
</div>
