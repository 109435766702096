<div *ngIf="infoBlok | async" class="infoBlok">
  <app-info-blok [infoText]="infoBlok | async"></app-info-blok>
</div>
<div *ngIf="koppelingAvailable | async" class="infoBlok">
  <div class="titel">
    <i class="koppeling"></i>
    <h1 class="bodyContentSemi">Toegang via Connect</h1>
  </div>
  <div class="content">
    <div class="classification">
      <i class="protect"></i>
      <span class="labelLabelSemi label succes">
        Vertrouwde koppeling
      </span>
    </div>
    <p class="bodySmallContent">
      <span *ngIf="(koppelingInformatie.lastSync | async); else noSync"> Laatste synchronisatie op {{ (koppelingInformatie.lastSync | async) }} </span>
      <ng-template #noSync>Dit product heeft nog geen data gesynchroniseerd</ng-template>
      <span #synchronisatieLabel class="labelLabelSemi label"  [class]="labelClass(synchronisatieLabel)" (click)="toggleLabel(synchronisatieLabel)">
        <i class="icon-arrow-open"></i> {{ labelText(synchronisatieLabel) }}
      </span>
    </p>
    <div *ngIf="isLabelOpengeklapt(synchronisatieLabel)" class="details">
      <p class="bodySmallContent" *ngIf="(koppelingInformatie.beginDatum | async)">Looptijd {{ (koppelingInformatie.beginDatum | async) }} {{ (koppelingInformatie.eindDatum | async) }}</p>
      <p class="bodySmallContent">Aangemaakt op {{ (koppelingInformatie.createdAt | async) }} door {{ (koppelingInformatie.createdBy | async) }}</p>
      <p class="bodySmallContent" *ngIf="(koppelingInformatie.modifiedAt | async)">Laatst bewerkt op {{ (koppelingInformatie.modifiedAt | async) }} door {{ (koppelingInformatie.modifiedBy | async) }}</p>
    </div>
  </div>
</div>

<div  *ngIf="(koppelingAvailable | async) && !(koppelpartij$ | async)?.opLeerlingNiveauGeautoriseerd" class="infoBlok">
  <div class="titel">
    <i class="groepen"></i>
    <h1 class="bodyContentSemi">Doelgroepen</h1>
  </div>
  <div class="content">
    <p class="bodySmallContent">
      Toegang tot {{ (koppelingInformatie.aantalLeerlingen | async) }} van {{ (koppelingInformatie.aantalVestigingen | async) }}
      <span #doelgroepenLabel class="labelLabelSemi label" [class]="labelClass(doelgroepenLabel)" (click)="toggleLabel(doelgroepenLabel)">
        <i class="icon-arrow-open"></i> {{ labelText(doelgroepenLabel) }}
      </span>
    </p>
    <div *ngIf="isLabelOpengeklapt(doelgroepenLabel)" class="details details--bordered">
      <p class="bodySmallContentSemi" *ngIf="(koppelingInformatie.vestigingen | async)"><i class="marker"></i>{{ (koppelingInformatie.vestigingen | async) }}</p>
      <p class="bodySmallContentSemi" *ngIf="(koppelingInformatie.onderwijsSoorten | async)"><i class="mortarBoard"></i>{{ (koppelingInformatie.onderwijsSoorten | async) }}</p>
      <p class="bodySmallContentSemi" *ngIf="(koppelingInformatie.leerjaren | async)"><i class="mortarBoard"></i>{{ (koppelingInformatie.leerjaren | async) }}</p>
      <p class="bodySmallContentSemi" *ngIf="(koppelingInformatie.vakken | async)"><i class="leerlingen"></i>{{ (koppelingInformatie.vakken | async) }}</p>
      <ng-container *ngFor="let vestiging of (koppelingInformatie.toekomstigeVestigingen | async)">
        <p class="bodySmallContentSemi disabled">
          <i class="marker"></i>
          {{ vestiging.namen }}
          <span class="label labelLabelSemi">Vanaf {{vestiging.begindatum}}</span>
        </p>
      </ng-container>
    </div>
  </div>
</div>

<div *ngIf="!(koppelingAvailable | async) && (koppelpartij$ | async)?.opLeerlingNiveauGeautoriseerd" class="infoBlok">
  <div class="titel">
    <i class="groepen"></i>
    <h1 class="bodyContentSemi">Doelgroepen</h1>
  </div>
  <div class="content">
    <p class="bodySmallContent">
      Bij het aanmaken van deze koppeling kun je enkel aangeven welke datavelden de koppelpartij kan ontvangen. Je kunt
      niet aangeven voor welke specifieke doelgroep deze data wordt gedeeld, want de leerling of diens ouders/verzorgers
      kunnen hiervoor zelf toestemming geven. Dit gebeurt bij de eerste keer dat de leerling inlogt bij
      {{ (koppelpartij$ | async)?.koppelpartijNaam }}
    </p>
  </div>
</div>

<div *ngIf="(koppelingAvailable | async) && (koppelpartij$ | async)?.opLeerlingNiveauGeautoriseerd" class="infoBlok">
  <div class="titel">
    <i class="groepen"></i>
    <h1 class="bodyContentSemi">Doelgroepen</h1>
  </div>
  <div class="content">
    <p class="bodySmallContent">
      Dit product heeft alleen toegang tot leerlingen die toestemming hebben gegeven
      <span #doelgroepenLabel class="labelLabelSemi label" [class]="labelClass(doelgroepenLabel)" (click)="toggleLabel(doelgroepenLabel)">
        <i class="icon-arrow-open"></i> {{ labelText(doelgroepenLabel) }}
      </span>
    </p>
    <div *ngIf="isLabelOpengeklapt(doelgroepenLabel)" class="details">
      <p class="bodySmallContentSemi" *ngIf="(koppelingInformatie.vakken | async)">
        <span class="icon_label">
          <i class="leerlingen"></i>
          <span *ngIf="(koppelingInformatie.aantalIndividueleLeerlingen | async) > 0" class="labelLabelSemi label">{{ (koppelingInformatie.aantalIndividueleLeerlingen | async)}}</span>
        </span>
        {{ (koppelingInformatie.leerlingen | async) }}
      </p>
    </div>
  </div>
</div>

<div class="infoBlok">
  <div class="titel">
    <i class="schild"></i>
    <h1 class="bodyContentSemi">Datatoegang</h1>
  </div>
  <div class="content">
    <p *ngIf="koppelingAvailable | async; else noKoppelingAvailable" class="bodySmallContent">
      Velden met een <i class="icon-add"></i> zijn optioneel en door de school zelf toegevoegd.
    </p>
    <ng-template #noKoppelingAvailable>
      <p class="bodySmallContent">
        Velden met een <i class="icon-add"></i> zijn optioneel en kunnen bij het aanmaken van de koppeling ingesteld worden.
      </p>
    </ng-template>
  </div>
</div>

<div class="veldentable">
  <ng-container *ngFor="let endpoint of (koppelingInformatie.veldpermissies | async); let i = index">
    <div (click)="toggleEndpoint(endpoint)" [class]="endpointClass(endpoint, i)">
      <p class="bodyContentBold name">{{endpoint.naam}}</p>
      <i class="icon-arrow-open"></i>
      <span *ngIf="endpoint.writePermissies" class="label">
        <i class="uitwisselen"></i>
        <div class="tooltip">
          <p class="bodySmallContent">
            {{(koppelpartij$ | async)?.koppelpartijNaam}} kan ook enkele gegevens bewerken. Dit betreft alle velden met <i class="uitwisselen"></i> ernaast.
          </p>
        </div>
      </span>
      <div class="categorien">
        <ng-container *ngFor="let categorie of endpoint.categorien;">
          <div class="categorie">
            <p class="bodySmallContentSemi naam">{{categorie.naam}}</p>
            <div class="velden">
              <ng-container *ngFor="let veld of categorie.velden;">
                <div class="veld">
                  <div class="iconContainer"><i [class]="veldIconClass(veld)"></i></div>
                  <p class="bodyContent veldnaam">{{veld.naam}}</p>
                  <div *ngIf="veld.properties?.length > 0" class="remarkContainer">
                    <ng-container *ngFor="let prop of veld.properties;">
                      <div [class]="propertyClass(prop)">
                        <div class="tooltip remarkTooltip">
                          <p class="bodySmallContent">{{prop.description}}</p>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
