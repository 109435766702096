import { Router } from '@angular/router';
import moment, { Moment } from 'moment';
import {Observable, BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {map, take, withLatestFrom} from 'rxjs/operators';
import { AVGExtendedKoppeling, AVGExtendedKoppelpartij, AVGExtendedVak, AVGExtendedVeldpermissie, AVGExtendedVestiging } from '../dto/avg-dashboard-model-classes';
import { KoppelingAanmakenStap1ComponentViewModelParent } from '../layout/koppeling-aanmaken-stap1/koppeling-aanmaken-stap1-component-view-model';
import { Categorie, Endpoint, Veld } from '../layout/koppeling-aanmaken-stap2/koppeling-aanmaken-stap2.component';
import { KoppelingAanmakenStap3ComponentViewModelParent } from '../layout/koppeling-aanmaken-stap3/koppeling-aanmaken-stap3-component-view-model';
import { LeerlingFilters } from '../layout/select-leerlingen/select-leerlingen-component-view-model';
import { VestigingRow } from '../layout/select-vestigingen/select-vestigingen.component';
import { momentToLocalDate } from '../stateless/moments';
import {AppState} from '../state/app.state';
import {Store} from '@ngrx/store';
import {
  selectGecategoriseerdeVeldPermissies,
  selectGeselecteerdeKoppelingen,
  selectGeselecteerdeKoppelpartij,
  selectVestigingen
} from '../state/privacydashboard/privacydashboard.selectors';
import {saveKoppeling, saveOpLeerlingGeautoriseerdeKoppeling} from '../state/privacydashboard/privacydashboard.actions';
import {selectVestigingenParam} from '../state/router/router.selectors';
import {ExtendedKoppeling} from '../state/privacydashboard/privacydashboard.state';

export class KoppelingAanmakenPageViewModelImpl implements KoppelingAanmakenStap1ComponentViewModelParent,
  KoppelingAanmakenStap3ComponentViewModelParent {

    private onGetKoppelpartij: Observable<AVGExtendedKoppelpartij>;
    private onGetGebruikerVestigingData: Observable<AVGExtendedVestiging[]> = new BehaviorSubject(null);
    public onGetKoppelpartijNaam: Observable<string>;
    public onGetKoppelpartijUUID: Observable<string>;
    public isOpLeerlingNiveauGeautoriseerd: Observable<boolean>;

    // Voor bewerk page
    private onGetTeBewerkenVestigingen: Observable<string[]>;
    private onGetTeBewerkenData: Observable<ExtendedKoppeling>;
    private isBewerkenPage: boolean;

    onGetStap: BehaviorSubject<number> = new BehaviorSubject(1);
    onGetForm: Observable<KoppelingForm>;
    onGetMagWegnavigeren: BehaviorSubject<boolean> = new BehaviorSubject(true);
    onGetWritePermissionsAmount: Observable<number>;

    // Breedtefilters
    onGetVestigingenSelectie: Observable<VestigingRow[]>;
    onGetVestigingenSelectieFiltered: Observable<VestigingRow[]>;
    doSetVestigingenSelectie: BehaviorSubject<VestigingRow[]> = new BehaviorSubject([]);
    doSetVestigingenSelectieFiltered: BehaviorSubject<VestigingRow[]> = new BehaviorSubject([]);
    onGetLeerlingFilters: Observable<LeerlingFilters>;
    doSubmitLeerlingFilters: BehaviorSubject<LeerlingFilters>;
    onGetLeerlingFiltersEnabled: Observable<boolean>;
    doSetIsStap1Compleet: BehaviorSubject<boolean> = new BehaviorSubject(false);

    // Veldpermissies
    onGetVelden: Observable<Endpoint[]>;
    doSetVelden: BehaviorSubject<Endpoint[]> = new BehaviorSubject([]);
    doResetVelden: Subject<void> = new BehaviorSubject<void>(null);

    onGetVolgendeButtonActive: BehaviorSubject<boolean> = new BehaviorSubject(false);
    doVolgendeButtonAction: Subject<void> = new Subject();
    doVorigeButtonAction: Subject<void> = new Subject();

    private form: KoppelingForm = {
        vestigingenSelectie: [],
        leerlingSelectie: {
            validSelection: false,
            alleLeerlingenGeselecteerd: false,
            onderwijssoortNamen: [],
            geselecteerdeOnderwijssoorten: [],
            alleOnderwijssoortenGeselecteerd: false,
            leerjaren: [],
            geselecteerdeLeerjaren: [],
            alleLeerjarenGeselecteerd: false,
            vakNamen: [],
            geselecteerdeVakken: [],
            alleVakkenGeselecteerd: false
        },
        veldpermissies: [],
        ingangsdatum: moment(),
        akkoord: false
    };

    doSetForm: BehaviorSubject<KoppelingForm> = new BehaviorSubject(this.form);
    doSubmit: Subject<void> = new Subject();

    private stap = 1;
    private ingangsdatum: Moment = null;

    private subscriptions = [];

    constructor(private router: Router, private appState: Store<AppState>) {
      this.onGetForm = this.doSetForm;

      this.loadKoppelpartijInfo();
      this.loadBewerkenVariables();
      this.loadVestigingen();
      this.loadLeerlingFilters();
      this.loadVelden();
      this.loadDates();
      this.loadButtons();
    }

    private loadKoppelpartijInfo(): void {
      this.onGetKoppelpartij = this.appState.select(selectGeselecteerdeKoppelpartij);
      this.onGetKoppelpartijNaam = this.onGetKoppelpartij.pipe(map(k => k?.koppelpartijNaam));
      this.onGetKoppelpartijUUID = this.onGetKoppelpartij.pipe(map(k => k?.uuid));
      this.onGetWritePermissionsAmount = this.onGetKoppelpartij.pipe(map(k => k?.writePermissies?.length));
      this.isOpLeerlingNiveauGeautoriseerd = this.onGetKoppelpartij.pipe(map(k => k?.opLeerlingNiveauGeautoriseerd));
    }

    private loadBewerkenVariables(): void {
      this.isBewerkenPage = this.router.url.split('?')[0].toLowerCase().includes('koppelingbewerken');
      this.onGetTeBewerkenVestigingen = this.appState.select(selectVestigingenParam).pipe(take(1), map(vestigingen => this.isBewerkenPage ? vestigingen?.split(',') : []));
      this.onGetTeBewerkenData = combineLatest([this.onGetTeBewerkenVestigingen, this.appState.select(selectGeselecteerdeKoppelingen)]).pipe(
        map(([vestigingen, koppelingen]) => {
          if (vestigingen?.length > 0 && this.isBewerkenPage && koppelingen?.length > 0) {
            const foundKoppelingen = koppelingen.filter(k => vestigingen.includes(k.vestiging.UUID));
            if (foundKoppelingen.length > 0) {
              const koppeling = {...foundKoppelingen[0]};
              koppeling.vakUUIDs = foundKoppelingen.map(k => k.vakUUIDs).reduce(this.uniqueConcat);
              return koppeling;
            }
          }
          return null;
        })
      );
    }

    private loadVestigingen(): void {
      this.onGetGebruikerVestigingData = combineLatest([this.appState.select(selectVestigingen), this.onGetKoppelpartij, this.onGetTeBewerkenVestigingen]).pipe(
        map(([vestigingen, koppelpartij, teBewerkenVestigingen]) => {
          return vestigingen?.filter(v =>
            !(koppelpartij?.koppelingen?.map(k => k.vestiging.UUID).includes(v.UUID)) ||
            teBewerkenVestigingen?.includes(v.UUID)
          ).sort(
            (a, b) => {
              if (a.naam < b.naam) return -1;
              if (a.naam > b.naam) return 1;
              return 0;
            });
        }),
      );

      this.onGetVestigingenSelectie = this.doSetVestigingenSelectie;
      this.onGetVestigingenSelectieFiltered = this.doSetVestigingenSelectieFiltered;

      this.subscriptions.push(this.doSetVestigingenSelectie
        .pipe(
          withLatestFrom(this.onGetTeBewerkenVestigingen)
        ).subscribe(([selectie, initialVestigingen]) => {
          if (!this.isInitialVestigingSelection(selectie, initialVestigingen) && selectie?.length) {
            this.onGetMagWegnavigeren.next(false);
          }
          this.form.vestigingenSelectie = selectie;
          this.doSetForm.next(this.form);
        }));

      this.subscriptions.push(this.onGetGebruikerVestigingData
        .pipe(
          withLatestFrom(this.onGetTeBewerkenVestigingen),
          map(([vestigingen, teBewerkenVestigingen]) => this.populateBeschikbareVestigingen(vestigingen, teBewerkenVestigingen)))
        .subscribe(rows => { this.doSetVestigingenSelectie.next(rows); this.doSetVestigingenSelectieFiltered.next(rows); }));
    }

    private loadButtons(): void {
      this.subscriptions.push(this.doVolgendeButtonAction
        .pipe(
          withLatestFrom(this.onGetKoppelpartijNaam, this.onGetKoppelpartijUUID, this.onGetVestigingenSelectieFiltered)
        ).subscribe(([_, naam, uuid, filteredVestigingen]) => {
            if (this.stap === 1) {
              this.doSetVestigingenSelectie.next(filteredVestigingen);
              this.onGetMagWegnavigeren.next(false);
            }
            if (this.stap < 3) {
                this.stap += 1;
                this.onGetStap.next(this.stap);
            }
            else {
              if (this.form.vestigingenSelectie?.filter(v => v.selected).length > 0) {
                const koppelingen: AVGExtendedKoppeling[] = this.form.vestigingenSelectie.filter(v => v.selected).map(vestiging => ({
                  vestiging: {naam: vestiging.naam, UUID: vestiging.vestiging.UUID},
                  permissieHash: null,
                  onderwijssoortAfkortingen: this.getSelectedOnderwijssoortAfkortingen(),
                  leerjaren: this.getSelectedLeerjaren(),
                  vakUUIDs: this.getSelectedVakUUIDs(vestiging),
                  veldpermissies: this.getSelectedVelden(),
                  begindatum: this.form.ingangsdatum ? momentToLocalDate(this.form.ingangsdatum) : momentToLocalDate(this.ingangsdatum),
                  einddatum: momentToLocalDate(this.form.einddatum)
                }));
                this.appState.dispatch(saveKoppeling({
                  koppelingen,
                  koppelpartij: {uuid, naam},
                  nieuw: !this.isBewerkenPage
                }));
              } else {
                const koppeling: AVGExtendedKoppeling = {
                  vestiging: null,
                  permissieHash: null,
                  onderwijssoortAfkortingen: [],
                  leerjaren: [],
                  vakUUIDs: [],
                  veldpermissies: this.getSelectedVelden(),
                  begindatum: this.form.ingangsdatum ? momentToLocalDate(this.form.ingangsdatum) : momentToLocalDate(this.ingangsdatum),
                  einddatum: momentToLocalDate(this.form.einddatum)
                };
                this.appState.dispatch(saveOpLeerlingGeautoriseerdeKoppeling({
                  koppeling,
                  koppelpartij: {uuid, naam},
                  nieuw: !this.isBewerkenPage
                }));
              }
              this.onGetMagWegnavigeren.next(true);
              this.router.navigateByUrl('privacydashboard');
            }}));

      this.subscriptions.push(this.doVorigeButtonAction.subscribe(_ => {
        if (this.stap === 1) {
          this.onGetMagWegnavigeren.next(true);
          this.router.navigateByUrl('privacydashboard');
        }
        this.stap -= 1;
        this.onGetStap.next(this.stap);
      }));

      this.subscriptions.push(combineLatest([this.doSetIsStap1Compleet, this.onGetForm, this.onGetStap])
        .subscribe(([stap1Compleet, form, stap]) => {
          if (stap === 1) {
            this.onGetVolgendeButtonActive.next(stap1Compleet);
          } else if (stap === 3) {
            this.onGetVolgendeButtonActive.next(validateKoppeling(form));
          } else {
            this.onGetVolgendeButtonActive.next(true);
          }
        })
      );
    }

    private loadVelden(): void {
      this.onGetVelden = this.doSetVelden;

      this.subscriptions.push(combineLatest([
        this.doResetVelden,
        this.appState.select(selectGecategoriseerdeVeldPermissies, {getEmptyFields: true}),
        this.onGetTeBewerkenData,
        this.onGetVestigingenSelectie
      ]).subscribe(
        ([_, vp, data, vestigingen]) => {
          vp.forEach(e => e.categorien.forEach(c => c.velden.map(v => {
            if (data) {
              v.selected = !!data?.veldpermissies.find(teBewerkenVeld => teBewerkenVeld.entityIndex === v.entityIndex && teBewerkenVeld.fieldIndex === v.fieldIndex)?.selected;
            } else {
              v.selected = false;
            }
          })));
          this.doSetVelden.next(this.onlyVeldpermissiesWithoutVestigingenOrForCurrentVestigingen(vp, vestigingen.filter(v => v.selected).map(v => v.vestiging.UUID)));
        }
      ));

      this.subscriptions.push(this.onGetVelden.subscribe( endpoints => {
        this.form.veldpermissies = endpoints;
        this.doSetForm.next(this.form);
      }));
    }

    private onlyVeldpermissiesWithoutVestigingenOrForCurrentVestigingen(endpoints: Endpoint[], vestigingUUIDs: string[])
    {
      return endpoints.map(e => 
        ({ 
          ...e, 
          categorien: e.categorien.map(c => 
            ({ 
              ...c, 
              velden: c.velden.filter(veld => this.filterVeldpermissiesOnVestigingProperty(veld, vestigingUUIDs)) 
            })
          ) 
        })
      );
    }

    private filterVeldpermissiesOnVestigingProperty(veld: Veld, vestigingUUIDs: string[])
    {
      if(veld.properties === null || veld.properties === undefined || veld.properties.find(prop => prop.type === 'Vestigingen') === undefined)
        return true;

      return !!vestigingUUIDs.find(vestigingUUID => {
        for(let property of veld.properties)
        {
          if(property !== null && property !== undefined && property.type === 'Vestigingen' && property.description.split(',').includes(vestigingUUID))
            return true;
        }
        return false;
      });
    }

    private loadLeerlingFilters(): void {
      this.doSubmitLeerlingFilters = new BehaviorSubject(this.form.leerlingSelectie);
      this.onGetLeerlingFilters = this.doSubmitLeerlingFilters;
      this.subscriptions.push(this.onGetTeBewerkenData.subscribe(data => {
        if (data) {
          const alleLeerlingenGeselecteerdBool = (data.vakNamen.length === 0) && (data.leerjaren.length === 0) && (data.onderwijssoortNamen.length === 0);
          const leerlingSelectie = {
            validSelection: !alleLeerlingenGeselecteerdBool,
            alleLeerlingenGeselecteerd: alleLeerlingenGeselecteerdBool,
            onderwijssoortNamen: data.onderwijssoortNamen.map((os: string) => os.toLowerCase()),
            geselecteerdeOnderwijssoorten: data.onderwijssoortAfkortingen,
            alleOnderwijssoortenGeselecteerd: data.onderwijssoortNamen.length === 0,
            leerjaren: data.leerjaren,
            geselecteerdeLeerjaren: data.leerjaren,
            alleLeerjarenGeselecteerd: data.leerjaren.length === 0,
            vakNamen: data.vakNamen.map((vn: string) => vn.toLowerCase()),
            geselecteerdeVakken: data.vakUUIDs,
            alleVakkenGeselecteerd: data.vakNamen.length === 0
          };
          this.doSubmitLeerlingFilters.next(leerlingSelectie);
        }
      }));

      this.subscriptions.push(this.doSubmitLeerlingFilters.pipe(withLatestFrom(this.onGetTeBewerkenData)).subscribe(([f, data]) => {
        if (f && data && !this.isInitialLeerlingSelection(f, data)) {
          this.onGetMagWegnavigeren.next(false);
        }
        this.form.leerlingSelectie = f;
        this.doSetForm.next(this.form);
      }));

      this.onGetLeerlingFiltersEnabled = this.onGetKoppelpartij.pipe(map(k => k && !k?.opLeerlingNiveauGeautoriseerd));
    }

    private loadDates(): void {
      this.subscriptions.push(this.onGetTeBewerkenData.subscribe(data => {
        if (data) {
          if (moment(data.begindatum) > moment()) {
            this.form.ingangsdatum = moment(data.begindatum);
          } else {
            this.form.ingangsdatum = null;
            this.ingangsdatum = moment(data.begindatum);
          }
          if (data.einddatum) {
            this.form.einddatum = moment(data.einddatum);
          }
          this.doSetForm.next(this.form);
        }
      }));
    }

    public setFormData(formData: KoppelingForm): void {
        this.doSetForm.next(formData);
    }

    private isInitialVestigingSelection(newSelection: VestigingRow[], initialSelection: string[]): boolean {
      return (newSelection?.length && JSON.stringify(newSelection?.filter(v => v.selected).map(v => v.vestiging.UUID)) === JSON.stringify(initialSelection));
    }

    private isInitialLeerlingSelection(newSelection: LeerlingFilters, initialSelection: ExtendedKoppeling): boolean {
      return (
        initialSelection &&
        newSelection.leerjaren === initialSelection.leerjaren &&
        newSelection.geselecteerdeVakken === initialSelection.vakUUIDs &&
        newSelection.geselecteerdeOnderwijssoorten === initialSelection.onderwijssoortAfkortingen &&
        newSelection.alleLeerlingenGeselecteerd === (initialSelection.vakNamen.length === 0) && (initialSelection.leerjaren.length === 0) && (initialSelection.onderwijssoortNamen.length === 0)
      );
    }

    private getSelectedOnderwijssoortAfkortingen(): string[] {
        return this.form.leerlingSelectie.alleLeerlingenGeselecteerd ?
            [] : this.form.leerlingSelectie.alleOnderwijssoortenGeselecteerd ?
                [] : this.form.leerlingSelectie.geselecteerdeOnderwijssoorten;
    }

    private getSelectedLeerjaren(): number[] {
        return this.form.leerlingSelectie.alleLeerlingenGeselecteerd ?
            [] : this.form.leerlingSelectie.alleLeerjarenGeselecteerd ?
                [] : this.form.leerlingSelectie.leerjaren;
    }

    private getSelectedVakUUIDs(vestiging: VestigingRow): string[] {
        if (this.form.leerlingSelectie.alleLeerlingenGeselecteerd || this.form.leerlingSelectie.alleVakkenGeselecteerd) {
            return [];
        }

        return vestiging.vestiging.aangebodenOnderwijssoorten.reduce((p, c) =>
            p.concat(c.aangebodenVakken), [] as AVGExtendedVak[])
                .reduce((p, c) => p.concat(c.UUIDs), [] as string[])
                .filter(uuid => this.form.leerlingSelectie.geselecteerdeVakken.includes(uuid));
    }

    private getSelectedVelden(): AVGExtendedVeldpermissie[] {
        return this.form.veldpermissies.reduce((p, c) =>
            p.concat(c.categorien), [] as Categorie[])
                .reduce((p, c) => p.concat(c.velden), [] as Veld[])
                .filter(veld => veld.selected)
                .reduce((list, veld) => {
                  if (veld.subFields?.length > 0) return [...list, ...veld.subFields];
                  else return [...list, veld];
                }, [] as Veld[])
                .map(veld => ({
                      name: null,
                      additionalProperties: veld.properties,
                      entityIndex: veld.entityIndex,
                      fieldIndex: veld.fieldIndex,
                      entityName: null,
                      selected: true,
                      verplicht: null
                    }));
        }

    public onDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    private populateBeschikbareVestigingen(vestigingen: AVGExtendedVestiging[], teBewerkenVestigingen: string[]): VestigingRow[] {
      if (vestigingen) {
        return vestigingen.map(v => ({ vestiging: v, naam: v.naam, selected: teBewerkenVestigingen?.includes(v.UUID) }));
      } else {
        return [];
      }
    }

    private uniqueConcat(a: string[], b: string[]): string[] {
      return [...a, ...(b.filter(x => !a.includes(x)))];
    }
}

function validateKoppeling(form: KoppelingForm): boolean {
    return ((form.ingangsdatum < form.einddatum) || (form.einddatum === null || form.einddatum === undefined)) && form.akkoord;
}

export interface KoppelingForm {
    vestigingenSelectie: VestigingRow[];
    leerlingSelectie: LeerlingFilters;
    veldpermissies: Endpoint[];
    ingangsdatum: Moment;
    einddatum?: Moment;
    akkoord: boolean;
}
