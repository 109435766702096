import {createSelector} from '@ngrx/store';
import {selectDevKoppelpartij} from '../app.state';
import {Endpoint} from './dev-koppelpartij.state';

export const selectKoppelpartij = createSelector(
  selectDevKoppelpartij,
  state => state.koppelpartij
);

export const selectOAuthClient = createSelector(
  selectDevKoppelpartij,
  state => state.oauthclient
);

export const selectAllEndpoints = createSelector(
  selectDevKoppelpartij,
  state => state.endpoints
);

export const selectAllLeesPermissies = createSelector(
  selectDevKoppelpartij,
  state => state.leespermissies
);

export const selectAllSchrijfPermissies = createSelector(
  selectDevKoppelpartij,
  state => state.schrijfpermissies
);

export const selectAllOrganisaties = createSelector(
  selectDevKoppelpartij,
  state => state.organisaties
);

export const selectSuccesfullySent = createSelector(
  selectDevKoppelpartij,
  state => state.succesfullySent
);

export const selectBenodigdeVeldpermissies = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.benodigdeVeldpermissies
);

export const selectKoppelpartijAfbeelding = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.koppelpartijAfbeeldingen
);

export const selectWritePermissies = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.writePermissies
);

export const selectGecategoriseerdeEndpoints = createSelector(
  selectAllEndpoints,
  selectAllLeesPermissies,
  selectAllSchrijfPermissies,
  selectBenodigdeVeldpermissies,
  selectWritePermissies,
  (allEndpoints, allLeespermissies, allSchrijfpermissies, benodigdeVeldpermissies, benodigdeWritepermissies) => {

    if (allSchrijfpermissies && allLeespermissies && allEndpoints) {
      let leespermissies = allLeespermissies;
      if (benodigdeVeldpermissies) {
        leespermissies = allLeespermissies.map(p => {
          const benodigdeVeldpermissieFound = benodigdeVeldpermissies.find(bp => p.fieldIndex === bp.fieldIndex && p.entityIndex === bp.entityIndex);
          if (benodigdeVeldpermissieFound) {
            return {...p, selected: benodigdeVeldpermissieFound.selected, verplicht: benodigdeVeldpermissieFound.verplicht, additionalProperties: benodigdeVeldpermissieFound.additionalProperties};
          }
          return p;
        });
      }

      const endpoints: Endpoint[] = [
        {naam: 'Leerling', categorien: [
            {naam: 'Personalia', velden: []},
            {naam: 'Plaatsing', velden: []},
            {naam: 'Onderwijsinhoudelijk', velden: []},
            {naam: 'Resultaten', velden: []},
            {naam: 'Rooster', velden: []}
          ]},
        {naam: 'Medewerker', categorien: [
            {naam: 'Personalia', velden: []},
            {naam: 'Aanstelling', velden: []}
          ]},
        {naam: 'Lesgroep', categorien: [
            {naam: 'Algemeen', velden: []},
          ]},
        {naam: 'Ouder/Verzorger', categorien: [
            {naam: 'Personalia', velden: []},
            {naam: 'Leerlingen', velden: []}
          ]},
        {naam: 'Leerling account', categorien: [
            {naam: 'Algemeen', velden: []},
          ]},
        {naam: 'Medewerker account', categorien: [
            {naam: 'Algemeen', velden: []},
          ]},
        {naam: 'Ouder/Verzorger account', categorien: [
            {naam: 'Algemeen', velden: []},
          ]},
        {naam: 'Vestiging', categorien: [
            {naam: 'Algemeen', velden: []},
            {naam: 'Aanmeldportaal', velden: []}
          ]}
      ];

      const medewerkerEndpoint = endpoints.find( e => e.naam === 'Medewerker');
      const leerlingEndpoint = endpoints.find( e => e.naam === 'Leerling');
      const ouderEndpoint = endpoints.find( e => e.naam === 'Ouder/Verzorger');

      leespermissies = [...leespermissies].filter(a => !a.additionalProperties.find(p => p.type === 'NestedEntity'));

      for (const permission of leespermissies) {
        const permissionMapped = {
          naam: permission.name,
          entityIndex: permission.entityIndex,
          fieldIndex: permission.fieldIndex,
          selected: permission.selected,
          verplicht: permission.verplicht,
          properties: permission.additionalProperties,
          subFields: null,
        };
        const endpoint = endpoints.find(e => permission.entityName === e.naam);
        if (endpoint) {
          if (
            ['Leerling', 'Medewerker'].includes(endpoint.naam) &&
            ['Vestiging', 'Hoofdvestiging', 'Lesgroepen', 'Vakkeuzes', 'Is docent', 'Functie'].includes(permission.name)
          ) {
            endpoint.categorien[1].velden.push(permissionMapped);
          } else {
            endpoint.categorien[0].velden.push(permissionMapped);
          }
        }
        if (permission.entityName === 'Leerling referentie') {
          ouderEndpoint.categorien[1].velden.push(permissionMapped);
        }
        if (permission.entityName === 'Plaatsing') {
          leerlingEndpoint.categorien[1].velden.push(permissionMapped);
        }
        if (permission.entityName === 'Resultaten') {
          leerlingEndpoint.categorien[3].velden.push(permissionMapped);
        }
        if (permission.entityName === 'Medewerker pasfoto') {
          medewerkerEndpoint.categorien[0].velden.push(permissionMapped);
        }
        if (permission.entityName === 'Leerling pasfoto') {
          leerlingEndpoint.categorien[0].velden.push(permissionMapped);
        }
        if (permission.entityName === 'Huiswerk') {
          leerlingEndpoint.categorien[2].velden.push(permissionMapped);
        }
        if (permission.entityName === 'Rooster') {
          leerlingEndpoint.categorien[4].velden.push(permissionMapped);
        }
      }

      let schrijfpermissies = [];
      if (benodigdeWritepermissies) {
        schrijfpermissies = allSchrijfpermissies.filter(p => benodigdeWritepermissies.find(bp => p.index === bp.index));
      }

      allSchrijfpermissies.forEach(sp => {
        let addToEndpoint = endpoints.find(e => e.naam === sp.endpoint);
        if (!addToEndpoint) {
          addToEndpoint = endpoints.find(e => e.categorien.find(c => c.naam === sp.endpoint));
        }
        addToEndpoint.categorien.push({
          naam: 'Schrijfpermissies', velden: [{
            entityIndex: null,
            fieldIndex: sp.index,
            naam: sp.naam,
            selected: false,
            verplicht: schrijfpermissies.find(p => p.index === sp.index),
            properties: [],
          }]
        });
      });

      return endpoints.map(e => {
        e.categorien = e.categorien.filter(c => c.velden.length > 0);
        return e;
      }).filter(e => e.categorien.length > 0);

    } else return [];
  }
);

export const selectUUID = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.uuid
);

export const selectKoppelpartijNaam = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.koppelpartijNaam
);

export const selectKoppelpartijOmschrijving = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.koppelpartijOmschrijving
);

export const selectKoppelpartijKorteOmschrijving = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.koppelpartijKorteOmschrijving
);

export const selectDocentLink = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.docentLink
);

export const selectLeerlingLink = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.leerlingLink
);

export const selectKoppelpartijAfbeeldingen = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.koppelpartijAfbeeldingen
);

export const selectOAuthClientId = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.oAuthClientId
);

export const selectKoppelpartijBedrijfsnaam = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.koppelpartijBedrijfsnaam
);

export const selectKoppelpartijLogoUrl = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.koppelpartijLogoUrl
);

export const selectEndpoints = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.endpoints
);

export const selectOrganisaties = createSelector(
  selectKoppelpartij,
  koppelpartij => koppelpartij?.zichtbaarVoorOrganisaties
);
