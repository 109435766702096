import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-koppeling-aanmaken-stap2',
    templateUrl: './koppeling-aanmaken-stap2.component.html',
    styleUrls: ['./koppeling-aanmaken-stap2.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KoppelingAanmakenStap2Component implements OnInit, OnDestroy {

    public toonUitleg = false;
    public toonResetTooltip = false;

    public bevatOptioneleVelden: boolean = true;

    @Input()
    public viewModel: KoppelingAanmakenStap2ComponentViewModel;

    private subscriptions: Subscription[] = [];

    constructor() { }

    ngOnInit(): void {
        this.subscriptions.push(this.viewModel.onGetVelden.pipe(map(e => {
            let heeftOptioneleVelden = false;
            e.forEach(ep => ep.categorien.forEach(c => c.velden.forEach(v => {
                if (!v.verplicht) heeftOptioneleVelden = true;
            })));
            return heeftOptioneleVelden;
        })).subscribe(heeftOptioneleVelden => this.bevatOptioneleVelden = heeftOptioneleVelden));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    public endpointClass(endpoint: Endpoint, index: number): string {
        let cl: string = index % 2 !== 0 ? 'endpoint' : 'endpoint uneven';
        // Ruimte maken voor tooltip omdat die anders achter de button bar valt
        if (endpoint.categorien.find(c => c.velden[c.velden.length - 1].properties?.find(prop => prop.type === 'warning'))) cl += ' roomForTooltip';
        return cl;
    }

    public veldIconClass(veld: Veld): string {
        return veld.verplicht ? 'icon dot' : veld.selected ? 'icon checked' : 'icon unchecked';
    }

    public veldClass(veld: Veld): string {
        return veld.verplicht ? 'veld' : 'veld clickable';
    }

    public onVeldClicked(veld: Veld): void {
        veld.selected = !veld.selected;
    }

    public showUitleg(): void {
        this.toonUitleg = true;
    }

    public closeUitleg(): void {
        this.toonUitleg = false;
    }

    public showResetTooltip(): void {
        this.toonResetTooltip = true;
    }

    public hideResetTooltip(): void {
        this.toonResetTooltip = false;
    }

    public propertyClass(prop: VeldProperty): string {
        switch (prop.type) {
            case 'warning':
                return 'remark warning';
            case 'write':
                return 'remark write';
            case 'none':
                return '';
            default:
                return 'remark';
        }
    }
}

export interface KoppelingAanmakenStap2ComponentViewModel {
    onGetKoppelpartijNaam: Observable<string>;
    onGetVelden: Observable<Endpoint[]>;
    doResetVelden: Subject<void>;
}

export interface Endpoint {
    naam: string;
    writePermissies: boolean;
    categorien: Categorie[];
}

export interface Categorie {
    naam: string;
    velden: Veld[];
}

export interface Veld {
    verplicht: boolean;
    selected: boolean;
    naam: string;
    entityIndex: number;
    fieldIndex: number;
    properties: VeldProperty[];
    subFields: any;
}

export interface VeldProperty {
    description: string;
    type: string;
}
