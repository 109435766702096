import { Moment } from 'moment';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { KoppelingForm } from '../../koppeling-aanmaken-page/koppeling-aanmaken-page-view-model-impl';
import { Feedback } from '../select-date/select-date.component';

export class KoppelingAanmakenStap3ComponentViewModel {
    public onGetAantalBasisvelden: Observable<number>;
    public onGetAantalVestigingen: Observable<number>;
    public onGetAantalWritePermissions: Observable<number>;
    public onGetAantalExtraVelden: Observable<number>;
    public onGetIsSelectAlleLeerlingen: Observable<boolean>;
    public onGetIngangsdatum: Observable<Moment>;
    public onGetIngangsdatumFeedback: BehaviorSubject<Feedback> = new BehaviorSubject(null);
    public onGetEinddatum: Observable<Moment>;
    public onGetEinddatumFeedback: BehaviorSubject<Feedback> = new BehaviorSubject(null);
    public onGetIsAkkoord: Observable<boolean>;
    public onGetKoppelpartijNaam: Observable<string>;
    public isOpLeerlingNiveauGeautoriseerd: Observable<boolean>;

    public doSetIngangsdatum: BehaviorSubject<Moment> = new BehaviorSubject(null);
    public doSetEinddatum: BehaviorSubject<Moment> = new BehaviorSubject(null);
    public doSetIsAkkoord: BehaviorSubject<boolean> = new BehaviorSubject(false as boolean);

    private form: KoppelingForm;
    private processingFormChange: boolean = false;

    private subscriptions: Subscription[] = [];

    public constructor(private parent: KoppelingAanmakenStap3ComponentViewModelParent) {
        this.onGetIngangsdatum = this.doSetIngangsdatum;
        this.onGetEinddatum = this.doSetEinddatum;
        this.onGetIsAkkoord = this.doSetIsAkkoord;
        this.onGetKoppelpartijNaam = this.parent.onGetKoppelpartijNaam;
        this.isOpLeerlingNiveauGeautoriseerd = this.parent.isOpLeerlingNiveauGeautoriseerd;
        this.onGetAantalWritePermissions = this.parent.onGetWritePermissionsAmount;

        this.onGetAantalBasisvelden = this.parent.onGetForm.pipe(map(form => {
            let aantalBasisvelden = 0;
            form.veldpermissies.forEach(e => {
                e.categorien.forEach(c => {
                    aantalBasisvelden += c.velden.filter(v => v.verplicht).length;
                });
            });
            return aantalBasisvelden;
        }));

        this.onGetAantalVestigingen = this.parent.onGetForm.pipe(map(form =>
            form.vestigingenSelectie.filter(v => v.selected).length
        ));

        this.onGetAantalExtraVelden = this.parent.onGetForm.pipe(map(form => {
            let aantalExtraVelden = 0;
            form.veldpermissies.forEach(e => {
                e.categorien.forEach(c => {
                    aantalExtraVelden += c.velden.filter(v => !v.verplicht && v.selected).length;
                });
            });
            return aantalExtraVelden;
        }));

        this.onGetIsSelectAlleLeerlingen = this.parent.onGetForm.pipe(map(form => form.leerlingSelectie.alleLeerlingenGeselecteerd));

        this.subscriptions.push(this.parent.onGetForm.subscribe(form => {
            this.form = form;
            this.processingFormChange = true;
            this.doSetIngangsdatum.next(form.ingangsdatum);
            this.doSetEinddatum.next(form.einddatum);
            this.doSetIsAkkoord.next(this.form.akkoord);
            this.processingFormChange = false;
        }));

        this.subscriptions.push(this.doSetIngangsdatum.subscribe(ingangsdatum => {
            if (this.processingFormChange) return;

            this.form.ingangsdatum = ingangsdatum;

            if (this.form.einddatum !== null && this.form.einddatum < ingangsdatum) {
                this.onGetIngangsdatumFeedback.next({error: true, message: 'Ingangsdatum kan niet na einddatum liggen.'});
            }
            else {
                this.onGetIngangsdatumFeedback.next(null);
                this.onGetEinddatumFeedback.next(null);
            }

            this.parent.doSetForm.next(this.form);
        }));

        this.subscriptions.push(this.doSetEinddatum.subscribe(einddatum => {
            if (this.processingFormChange) return;

            this.form.einddatum = einddatum;

            if (this.form.ingangsdatum >= einddatum && einddatum !== null) {
                this.onGetEinddatumFeedback.next({error: true, message: 'Einddatum kan niet voor of op de ingangsdatum liggen.'});
            }
            else {
                this.onGetIngangsdatumFeedback.next(null);
                this.onGetEinddatumFeedback.next(null);
            }

            this.parent.doSetForm.next(this.form);
        }));

        this.subscriptions.push(this.doSetIsAkkoord.subscribe(akkoord => {
            if (this.processingFormChange) return;

            this.form.akkoord = akkoord;
            this.parent.doSetForm.next(this.form);
        }));
    }

    onDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}

export interface KoppelingAanmakenStap3ComponentViewModelParent {
    onGetForm: Observable<KoppelingForm>;
    onGetKoppelpartijNaam: Observable<string>;
    onGetWritePermissionsAmount: Observable<number>;
    isOpLeerlingNiveauGeautoriseerd: Observable<boolean>;

    doSetForm: BehaviorSubject<KoppelingForm>;
    doSubmit: Subject<void>;
}
